<template>
  <div class="justify-center m-3">
    <p class="text-3xl">Dashboard</p>
    <div class="aspect-w-3 aspect-h-2">
      <Graph
        :name="'workResultWeeks'"
        :xlabel="'Datum, Kalenderwoche'"
        :xType="'CW'"
        :ylabel="'Arbeitszeit [in Stunden]'"
        :yMin=0
        :yIntervalls=5
        :xIntervalls=2 />
    </div><br>
    <div class="aspect-w-3 aspect-h-2">
      <Graph
        :name="'siteVisits'"
        :xlabel="'Datum'"
        :xType="'DATE'"
        :ylabel="'Seitenbesuche'"
        :yMin=0
        :yIntervalls=5
        :xIntervalls=2
        :series="graphSeries"/>
    </div>
  </div>
</template>

<script>
import Graph from '../components/graph/Graph.vue';

export default {
  components: {
    Graph,
  },
  data() {
    return {
      pageHead: 'Arbeitszeit',
      tableView: 'fth_hours',
      yMax: 10,
    };
  },
  computed: {
  },
  methods: {
  },
  created() {
    const dataJSON = {
      user_id: this.$store.state.user.userId,
      session_id: this.$store.state.user.session,
      forEmployee: this.$store.state.user.userId, // TODO
    };

    this.$store.state.socket.on('workResultWeeks', (data) => {
      console.log('workResultWeeks');
      console.log(data);
      // this.$store.commit('addGraph', 'workResultWeeks');
      let seriesName = '';
      if (data.forEmployee === 1) {
        seriesName = 'Stefan';
      } else if (data.forEmployee === 2) {
        seriesName = 'David';
      } else {
        seriesName = 'Unbekannt';
      }
      const seriesData = { graphName: 'workResultWeeks', seriesName };
      this.$store.commit('addSeries', seriesData);
      const workTimeResultWeeks = data.res;

      for (let i = 0; i < workTimeResultWeeks.length; i += 1) {
        // day1: timestamp vom 01.01.YYYY in ms seit 1.1.1970
        // day2: ms bis zur entsprechenden KW (86400000 ms = 1 Tag)
        const day1 = new Date(workTimeResultWeeks[i].Jahr, 0, 1); // (YYYY-Januar-1)
        const day2 = (workTimeResultWeeks[i].KW * 7
          - (new Date(workTimeResultWeeks[i].Jahr, 0, 1).getDay()) + 1)
          * 86400000; // tage -> ms
        const day3 = day1.valueOf() + day2;

        seriesData.x = day3;
        seriesData.y = workTimeResultWeeks[i].Minuten / 60;
        this.$store.commit('addValue', seriesData);
        // console.log(`Minuten Hinzugefügt: ${this.workTimeResultWeeks[i].Minuten}`);
      }
    });

    this.$store.state.socket.on('getSiteVisits', (data) => {
      console.log('getSiteVisits');
      console.log(data);

      let seriesName = data.site;

      if (seriesName === '') {
        seriesName = 'home';
      }

      const seriesData = { graphName: 'siteVisits', seriesName };
      this.$store.commit('addSeries', seriesData);
      // this.$store.state.graphs.workResultWeeks = {};
      // console.log(this.$store.state.graphs);

      const workTimeResultWeeks = data.res;

      for (let i = 0; i < workTimeResultWeeks.length; i += 1) {
        seriesData.x = new Date(workTimeResultWeeks[i].datum).valueOf();
        seriesData.y = workTimeResultWeeks[i].anzahl;
        this.$store.commit('addValue', seriesData);
        // console.log(`Minuten Hinzugefügt: ${this.workTimeResultWeeks[i].Minuten}`);
      }
    });

    dataJSON.forEmployee = 1; // this.$store.state.user.userId; // für sich selbst
    this.$store.state.socket.emit('getWork', dataJSON);

    dataJSON.forEmployee = 2; // für David
    this.$store.state.socket.emit('getWork', dataJSON);

    dataJSON.site = 'shardcraft-pegel';
    this.$store.state.socket.emit('getSiteVisits', dataJSON);

    dataJSON.site = 'impressum';
    this.$store.state.socket.emit('getSiteVisits', dataJSON);

    dataJSON.site = 'dsgvo';
    this.$store.state.socket.emit('getSiteVisits', dataJSON);

    dataJSON.site = '';
    this.$store.state.socket.emit('getSiteVisits', dataJSON);
  },

};
</script>
