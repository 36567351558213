<template>
  <div class="justify-center m-3">
    <p class="text-3xl">Passwort ändern</p>
    <label classs="block">
      <input v-model="name" type='email'
        placeholder='E-Mail'
        class="shadow border rounded w-5/6 py-2 px-3 m-2 place-items-center">
    </label>
    <br>
    <input v-model="passwordOld" type='password'
      placeholder="Aktuelles Passwort" class="shadow border rounded w-5/6 py-2 px-3 m-2">
    <br>
    <input v-model="passwordNew1" type='password'
      placeholder="Neues Passwort"
      :class="`${checkPwLength ?
        'shadow rounded w-5/6 py-2 px-3 m-2 border' :
        'shadow rounded w-5/6 py-2 px-3 m-2 border border-red-500'}`">
    <br>
    <div v-if="!checkPwLength">
      <p class="text-red-500 text-xs italic">
        Das neue Passwort muss mindestens 8 Zeichen haben.
      </p>
    </div>
    <input v-model="passwordNew2" type='password'
      placeholder="Wiederholung neues Passwort"
      :class="`${checkPwReplication ?
        'shadow rounded w-5/6 py-2 px-3 m-2 border' :
        'shadow rounded w-5/6 py-2 px-3 m-2 border border-red-500'}`">
    <br>
    <div v-if="!checkPwReplication">
      <p class="text-red-500 text-xs italic">
        Die Wiederholung stimmt nicht mit dem neuen Passwort überein.
      </p>
    </div>
    <div class="flex justify-center">
      <button
        class="btn btn-red"
        @click="setNewPassword($event)"
        :disabled="!checkPwOK">
        Passwort ändern</button>
    </div>
    <div v-if="response != ''">
      <p class="text-red-500 text-xs italic">
        {{ response }}
      </p>
    </div>
  </div>
</template>

<script>

export default {
  components: {
  },
  data() {
    return {
      name: '',
      passwordOld: '',
      passwordNew1: '',
      passwordNew2: '',
      response: '',
    };
  },
  computed: {
    checkPwLength() {
      return this.passwordNew1.length >= 8;
    },
    checkPwReplication() {
      return this.passwordNew1 === this.passwordNew2;
    },
    checkPwOK() {
      return this.checkPwLength && this.checkPwReplication;
    },
  },
  methods: {
    setNewPassword() {
      const data = {
        user_id: this.$store.state.user.userId,
        session_id: this.$store.state.user.session,
        email: this.name,
        passwordOld: this.passwordOld,
        passwordNew1: this.passwordNew1,
        passwordNew2: this.passwordNew2,
      };

      this.$store.state.socket.emit('change password', data);
      // console.log(`socket emit: login ${JSON.stringify(data)}`);
    },
  },
  created() {
    this.$store.state.socket.on('change password', (data) => {
      console.log('change password');
      console.log(data);
      this.response = data.response;
    });
  },

};
</script>
