<template>
  <div class="justify-center m-3">
    <p class="text-3xl">Pegelmessung Eichelbach</p>
    <div class="aspect-w-3 aspect-h-2">
      <Graph
        :name="'pegelEichelbach'"
        :legendEnabled=0
        :xlabel="''"
        :xType="'DATETIME'"
        :ylabel="'Pegel in m'"
        :yMin=0
        :yMax=2
        :yIntervalls=5
        :xIntervalls=4 />
    </div><br>
    <div class="flex justify-center">
      <button class="btn btn-red" @click="updateSeries(1)">1 Tag</button>
      <button class="btn btn-red" @click="updateSeries(3)">3 Tage</button>
      <button class="btn btn-red" @click="updateSeries(7)">7 Tage</button>
      <button class="btn btn-red" @click="updateSeries(30)">30 Tage</button>
    </div>
  </div>
</template>

<script>
import Graph from '../components/graph/Graph.vue';

export default {
  components: {
    Graph,
  },
  data() {
    return {
      pageHead: 'Pegel',
      tableView: 'fth_hours',
      yMax: 10,
      pegelData: {},
      timeSpan: '1day',
    };
  },
  computed: {
  },
  methods: {
    updateSeries(timeSpan) {
      fetch(`https://rest.fth-engineering.de/sensor/1/days/${timeSpan}`)
        .then((res) => res.json())
        .then((data) => {
          this.pegelData = data.res;
          this.$store.commit('resetGraph', 'pegelEichelbach');
          const seriesName = 'Eichelbach';
          const seriesData = { graphName: 'pegelEichelbach', seriesName };
          this.$store.commit('addSeries', seriesData);
          console.log(data.res);
          for (let i = 0; i < data.res.length; i += 1) {
            // seriesData.x = i; // new Date(data.res[i].time).valueOf();
            seriesData.x = new Date(`${data.res[i].date}T${data.res[i].time}`).valueOf();
            seriesData.y = 2.22 - (data.res[i].avgSensVal / 1000);
            if (data.res[i].stdSensVal < 100) { // Standardabweichung
              this.$store.commit('addValue', seriesData);
            }
          }
        });
    },
  },
  mounted() {
    this.updateSeries('1day');
  },
};
</script>
