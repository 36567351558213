<template>
  <div class="justify-center m-3">
    <p class="text-3xl">Logout...</p>
  </div>
</template>

<script>
import io from 'socket.io-client';
import Cookies from 'js-cookie';

export default {
  components: {
  },
  data() {
    return {
      cookieEnabled: false,
    };
  },

  // const socket = io('https://www.fth-engineering.de:3003', { transports : ['websocket'] });

  created() {
    console.log('Starting Connection to WebSocket Server');
    this.$store.state.socket = io('https://www.fth-engineering.de:3003',
      { transports: ['websocket'] });
  },

  mounted() {
    this.logout();

    this.cookieEnabled = Cookies.get('fth_cookieEnabled');
    if (this.cookieEnabled) {
      Cookies.set('fth_cookieSessionLoggedIn', 'false');
      Cookies.set('fth_cookieSessionSession', '');
      Cookies.set('fth_cookieSessionUserId', -1);
    }

    this.$store.state.socket.on('logout successful', (data) => {
      console.log(data);
      this.$store.state.user.loggedIn = false;
      this.$store.state.user.username = '';
      this.$store.state.user.session = '';
      this.$store.state.user.userId = -1;
      this.$store.state.user.name = '';

      this.$router.push('login');
    });
  },

  methods: {
    logout() {
      const data = {
        sessionId: this.$store.state.user.session,
      };

      this.$store.state.socket.emit('logout', data);
      console.log(`socket emit: logout ${JSON.stringify(data)}`);
    },
  },

};
</script>
